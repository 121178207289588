<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="filename"
        name="Filename"
        dense
        clearable
        hint="Cerca per nome del file"
        persistent-hint
      />

      <FormItem
        v-model="type"
        name="Tipo"
        dense
        clearable
        hint="Cerca per tipo del file"
        persistent-hint
      />
      <FormItem
        v-model="import_started_at"
        type="datepicker"
        name="Data Import"
        hint="Ricerca per Data Import"
        dense
        range
        :xl="2"
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapMutations } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'uploads/getFiltersFields',
  mutationType: 'uploads/SET_FILTER_FIELDS',
})

export default {
  name: 'UploadSectionSearch',
  components: {
    FormItem,
    BaseSectionSearch,
  },
  data() {
    return {
    }
  },
  computed: {
    ...authComputed,
    ...mapFields(['filename', 'type', 'import_started_at']),
  },
  beforeMount() {
  },
  methods: {
    ...mapMutations('uploads', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('reset')
    },
  },
}
</script>
