<template>
  <v-container fluid>
    <v-tabs
      v-model="currentTab"
      class="mt-6"
      background-color="transparent"
      @change="onTabChange"
    >
      <v-tab>
        <v-icon left> mdi-file-download-outline </v-icon>
        File importati
      </v-tab>
      <v-tab>
        <v-icon left> mdi-file-upload-outline </v-icon>
        File esportati
      </v-tab>

      <v-tab-item class="mt-4">
        <UploadSectionSearch @search="getUploadItems" @reset="resetUploadSearch" />
        <v-card class="mt-5">
          <v-card-text>
            <BaseTable
              ref="table"
              title="File importati"
              module="uploads"
              :headers="headers"
              :loading="loading"
              :elevation="0"
              :actions="actions"
              sortby="import_started_at"
              :sort-desc="true"
            >
              <template v-slot:item.import_started_at="{ item }">
                {{ item.import_started_at | momentOr('DD/MM/YYYY', '-') }}
              </template>
            </BaseTable>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item class="mt-4">
        <ExportSectionSearch @search="getExportItems" @reset="resetExportSearch" />
        <v-card class="mt-5">
          <v-card-text>
            <BaseTable
              ref="table"
              title="File esportati"
              module="exports"
              :headers="headersExport"
              :loading="loading"
              :elevation="0"
              :actions="actionsExport"
              sortby="export_started_at"
              :sort-desc="true"
            >
              <template v-slot:item.export_started_at="{ item }">
                {{ item.export_started_at | momentOr('DD/MM/YYYY', '-') }}
              </template>
            </BaseTable>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue';
import UploadSectionSearch from '@components/download/UploadSectionSearch.vue';
import ExportSectionSearch from '@components/download/ExportSectionSearch.vue';
import { mapActions, mapMutations } from "vuex";
import {authComputed} from "@state/helpers";
import FileSaver from 'file-saver';

export default {
  name: 'DownloadSearch',
  components: {
    BaseTable,
    UploadSectionSearch,
    ExportSectionSearch,
  },
  page: {
    title: 'Download file',
  },
  data() {
    return {
      currentTab: 'import',
      tabs: [
        {
          key: 'import',
          title: 'Import',
        },
        {
          key: 'export',
          title: 'Export',
        },
      ],
      headers: [
        {
          text: 'Filename',
          value: 'filename',
        },
        {
          text: 'Tipologia',
          value: 'type',
        },
        {
          text: 'Numero record',
          value: 'num_records',
        },
        {
          text: 'Di cui processati',
          value: 'processed',
        },
        {
          text: 'Di cui errori',
          value: 'error',
        },
        {
          text: 'Data import',
          value: 'import_started_at',
        },
      ],
      headersExport: [
        {
          text: 'Filename',
          value: 'filename',
        },
        {
          text: 'Tipologia',
          value: 'type',
        },
        {
          text: 'Numero record',
          value: 'num_records',
        },
        {
          text: 'Di cui processati',
          value: 'processed',
        },
        {
          text: 'Di cui errori',
          value: 'error',
        },
        {
          text: 'Data export',
          value: 'export_started_at',
        },
      ],
      dialog: false,
      reconcile: false,
      transfer: false,
      actions: [
        {
          key: 'download',
          label: 'Scarica',
          icon: 'mdi-download',
          color: 'red',
          handler: this.download,
        },
      ],
      actionsExport: [
        {
          key: 'download',
          label: 'Scarica',
          icon: 'mdi-download',
          color: 'red',
          handler: this.downloadExport,
        },
        {
          key: 'download',
          label: 'Scarica PGP',
          icon: 'mdi-download',
          color: 'primary',
          handler: this.downloadExportPGP,
          onItemCondition: (item) => !['POSTEL_LOG', 'TRANSACTION_REPORT_MONTHLY_MP', 'TRANSACTION_REPORT_MONTHLY_FN'].includes(item.type),
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...authComputed,
  },
  beforeMount() {
    this.setFilters();
    this.setUploadScopes(['not_riconciliazione']);
  },
  methods: {
    ...mapActions('uploads', {
      getUploadItems: 'getItems',
    }),
    ...mapMutations('uploads', {
      setUploadFilters: 'SET_FILTERS',
      setUploadScopes: 'SET_SCOPES',
      resetExportFilters: 'RESET_FILTER_FIELDS',
    }),
    ...mapActions('exports', {
      getExportItems: 'getItems',
    }),
    ...mapMutations('exports', {
      setExportFilters: 'SET_FILTERS',
      resetExportFilters: 'RESET_FILTER_FIELDS',
    }),
    setFilters() {
    },
    resetUploadSearch() {
      this.setFilters();
      this.setUploadScopes(['not_riconciliazione']);
      this.getUploadItems();
    },
    resetExportSearch() {
      this.setFilters();
      this.getExportItems();
    },
    async onTabChange(index) {
      const { key } = this.tabs[index]
      switch (key) {
        default:
          break
      }
    },
    download(item) {
      const url = `${process.env.VUE_APP_BASE_URL}uploads/download/filename/${item.filename}/type/${item.type}?t=${this.currentUser.token}`
      FileSaver.saveAs(url, item.filename)
    },
    downloadExport(item) {
      const url = `${process.env.VUE_APP_BASE_URL}exports/download/filename/${item.filename}/type/${item.type}/ext/csv?t=${this.currentUser.token}`
      FileSaver.saveAs(url, item.filename)
    },
    downloadExportPGP(item) {
      const url = `${process.env.VUE_APP_BASE_URL}exports/download/filename/${item.filename}/type/${item.type}/ext/pgp?t=${this.currentUser.token}`
      FileSaver.saveAs(url, item.filename + '.pgp')
    },
  },
}
</script>
